<template>
  <main>
<!--    <h1>欢迎</h1>-->
<!--    <button @click="getSignature">加入会议</button>-->
  </main>
</template>

<script>
import axios from "axios";
import { ZoomMtg } from '@zoomus/websdk';
import {getMyMeetingList} from "@/serve/api/api";
export default {
  name: 'HelloWorld',
  created () {
    this.getUserInfo()
    // ZoomMtg.setZoomJSLib('https://jssdk.zoomus.cn/2.13.0/lib', '/av'); //中国
    ZoomMtg.setZoomJSLib('https://source.zoom.us/2.13.0/lib', '/av');
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareWebSDK();
    ZoomMtg.i18n.load('zh-CN');
    ZoomMtg.i18n.reload('zh-CN');
  },
  mounted() {
    // ZoomMtg.inMeetingServiceListener("onUserJoin", (data) => {
    //   console.log("inMeetingServiceListener onUserJoin", data);
    // });
  },
  data () {
    return {
      authEndpoint: "https://meeting.yunicu.com/web2", //jwt校验
      sdkKey: "CBj1aH1AQX2KhJilnSfYfw",
      meetingNumber: "",
      passWord: "",
      role: 0,
      userName: "",
      userEmail: "",
      registrantToken: '',
      zakToken: '',
      leaveUrl: "https://meeting.yunicu.com",
      // leaveUrl: "http://192.168.31.22:8080"
    }
  },
  methods: {
    async getUserInfo () {
      const type = this.$route.query.type
      const id = this.$route.query.id
      const name = this.$route.query.name
      const userId = this.$route.query.userId
      let liveInfo = JSON.parse(localStorage.getItem('liveInfo'))
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      // eslint-disable-next-line no-unused-vars
      let header = ''
      if (type === 'yunIcu') {
        header = 'https://yun-new-admin-api.yunicu.com/api/admin/getConsultation'
      } else if (type === 'apNet') {
        header = 'https://meeting-api.aphouse.cn/apent/meet/id'
      } else if (type === 'ie-learning') {
        header = 'https://meeting-api.ie-learning.cn/meetings/meeting/new/noe'
      }
      if (id && name) {
        const response = await getMyMeetingList(header, userId, id)
        if (response.code === 0) {
          if (response.data !== '' && response.data) {
            response.data[0].aType = type
            localStorage.setItem('liveInfo',JSON.stringify(response.data[0]))
            localStorage.setItem('userInfo',JSON.stringify({name}))
            liveInfo = JSON.parse(localStorage.getItem('liveInfo'))
            userInfo = JSON.parse(localStorage.getItem('userInfo'))
            this.meetingNumber = response.data[0].liveValue
            this.userName = name
            this.passWord = response.data[0].password
          }
        }
        this.$router.push({ query: {} });
      }else if (liveInfo && userInfo) {
        this.meetingNumber = liveInfo.liveValue
        this.userName = userInfo.name
        this.passWord = liveInfo.password
      } else {
        window.location.href = `${header.linkHeader}`
      }
      if (liveInfo.aType === 'yunIcu') {
        this.leaveUrl = 'https://meeting.yunicu.com'
      } else if (liveInfo.aType === 'apNet') {
        this.leaveUrl = 'https://meeting.aphouse.cn'
      } else if (liveInfo.aType === 'ie-learning') {
        this.leaveUrl = 'https://meeting.ie-learning.cn'
      }
      if (liveInfo) document.title = liveInfo.title
      this.getSignature()
    },
    getSignature() {
      axios.post(this.authEndpoint, {
        meetingNumber: this.meetingNumber,
        role: this.role
      })
          .then(res => {
            console.log(res.data.signature);
            this.startMeeting(res.data.signature);
          })
          .catch(error => {
            console.log(error);
          });
    },
    startMeeting(signature) {
      document.getElementById("zmmtg-root").style.display = "block";
      ZoomMtg.init({
        leaveUrl: this.leaveUrl,
        success: (success) => {
          console.log(success);
          ZoomMtg.join({
            signature: signature,
            sdkKey: this.sdkKey,
            meetingNumber: this.meetingNumber,
            passWord: this.passWord,
            userName: this.userName,
            userEmail: this.userEmail,
            tk: this.registrantToken,
            zak: this.zakToken,
            success: (success) => {
              console.log(success);
            },
            error: (error) => {
              console.log(error);
            }
          });
        },
        error: (error) => {
          console.log(error);
        }
      });


      // const button = document.getElementById("zmmtg-root").querySelector('.root-inner')
      // .querySelector('#content_container')
      // .querySelector('#content')
      // .querySelectorAll('.mini-layout-body')[1]
      // .querySelector('.form-group')
      // .querySelector('.mini-layout-body-controls')
      //     .querySelector('button')
      // const title = document.getElementById("zmmtg-root").querySelector('.root-inner')
      //     .querySelector('#content_container')
      //     .querySelector('#content')
      //     .querySelectorAll('.mini-layout-body')[0]
      //     .querySelector('.form-group')
      //     .querySelector('.mini-layout-body-title')
      //     // .querySelector('button')
      // // setTimeout(() => {
      // //   ap.click()
      // // },1000)
      // title.innerHTML = '欢迎加入我的会议，会议房间号为：111'
      // this.$nextTick(() => {
      //   button.click()
      // })
      // console.log(button,'ceshi-button')
      // console.log(title,'ceshi-title')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
main {
  width: 70%;
  margin: auto;
  text-align: center;
}

main button {
  margin-top: 20px;
  background-color: #2D8CFF;
  color: #ffffff;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
  display: inline-block;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  outline: none;
}

main button:hover {
  background-color: #2681F2;
}
</style>
